import menuItem from '@/serviceApi/menu_slide'
export const items = [


  {
    title:"Dashbord",
    icon:"mdi-view-dashboard",
    role:"admin",
    status: menuItem.reponse_menu('Dashbord'),
    to:"/dashbord"
  },

  {
    title:"Clients",
    icon:"mdi-account-multiple",
    role:"admin",
    status: menuItem.reponse_menu('Clients'),
    to:"/clients"
  },

  {
    title:"KYC-client",
    icon:"mdi-badge-account",
    role:"admin",
    status: menuItem.reponse_menu('KYC-client'),
    to:"/client-kyc"

  },
  {
    title:"KYC-partenaire",
    icon:"mdi-badge-account",
    role:"admin",
    status: menuItem.reponse_menu('KYC-partenaire'),
    to:"/client-kyc"

  },

  {
    title:"Finances",
    icon:"mdi-finance",
    role:"admin",
    status: menuItem.reponse_menu('Finances'),
    group:"finances",
    children:[
      {
        title:"Dashboard Finances",
        icon:"mdi-circle-medium",
        role:"admin",
        status:true,
        to:"dashbord-finances"
      },
      {
        title:"Recettes",
        icon:"mdi-circle-medium",
        role:"admin",
        status:true,
        to:"recettes-finances"
      },

      {
        title:"Depenses",
        icon:"mdi-circle-medium",
        role:"admin",
        status:true,
        to:"depenses-finances"
      },

      {
        title:"Fournisseurs Dollar",
        icon:"mdi-circle-medium",
        role:"admin",
        status:true,
        to:"achats-dollar"
      },

      {
        title:"Achats Dollar",
        icon:"mdi-circle-medium",
        role:"admin",
        status:true,
        to:"achats-dollar"
      },


    ]

  },


  {
    title:"Operations",
    icon:"mdi-compare-horizontal",
    role:"admin",
    status: menuItem.reponse_menu('Operations'),
    group:"operations",
    children:[
      {
        title:"Transactions encours",
        icon:"mdi-circle-medium",
        role:"admin",
        status:true,
        to:"transactions"
      },
      {
        title:"Transactions historique",
        icon:"mdi-circle-medium",
        role:"admin",
        status:true,
        to:"transactions-all"
      },
      {
        title:"PM Transactions",
        icon:"mdi-circle-medium",
        role:"admin",
        status:true,
        to:"transactions-pm"
      },
      {
        title:"Crypto Deposits",
        icon:"mdi-circle-medium",
        role:"admin",
        status:true,
        to:"crypto-deposits"
      },
      {
        title:"Transactions commission",
        icon:"mdi-circle-medium",
        role:"admin",
        status:true,
        to:"commissions-client"
      },
      {
        title:"Tarification",
        icon:"mdi-circle-medium",
        role:"admin",
        status:true,
        to:"tarification"
      },
    ]
  },

  {
    title:"Serveur",
    icon:"mdi-database-cog-outline",
    role:"admin",
    status: menuItem.reponse_menu('Serveur'),
    group:"serveurs",
    children:[
      {
        title:"Serveur Sms",
        icon:"mdi-circle-medium",
        role:"admin",
        status:true,
        to:"sms"
      },
      {
        title:"Serveur Blacklist",
        icon:"mdi-circle-medium",
        role:"admin",
        status:true,
        to:"blacklist"
      },
      {
        title:"Parametre  serveur",
        icon:"mdi-circle-medium",
        role:"admin",
        status:true,
        to:"parametre_server",

      },
      {
        title:"Monnaies",
        icon:"mdi-account-multiple",
        role:"admin",
        status:true,
        to:"monnaies"
      },
    ]
  },

  {
    title:"Parametre",
    icon:"mdi-cog-box",
    role:"admin",
    group:"parametre",
    status: menuItem.reponse_menu('Parametre'),
    children:[
      {
        title:"Monnaies",
        icon:"mdi-account-multiple",
        role:"admin",
        status:true,
        to:"monnaies"
      },
      {
        title:"Pays",
        icon:"mdi-account-multiple",
        role:"admin",
        status:true,
        to:"/commissions"

      },
      {
        title:"Compte admins",
        icon:"mdi-account-multiple",
        role:"admin",
        status:true,
        to:"compte-admins"
      },
      {
        title:"Partenaires",
        icon:"mdi-account-multiple",
        role:"admin",
        status:true,
        to:"partenaires"
      }
    ]
  },


 ];
